<template>
    <div class="row">
      <div class="col-lg-2 col-md-4">
        <router-link to="/akun" ><a-button type="primary" style="text-align:left" block>Ganti Kata Sandi</a-button></router-link>
        <router-link to="/parameter/syarat-dan-ketentuan"><a-button type="link" style="text-align:left" block>Syarat dan Ketentuan</a-button></router-link>
        <router-link to="/parameter/layanan-pelanggan"><a-button type="link" style="text-align:left" block>Layanan Pelanggan</a-button></router-link>
        <!-- <router-link v-if="isAdminBk" to="/parameter/faq"><a-button type="link" style="text-align:left" block>Faq</a-button></router-link> -->
        <!-- <router-link v-if="isAdminBk" to="/"><a-button type="link" style="text-align:left" block>Keluar</a-button></router-link> -->
      </div>
      <div class="col-lg-9 col-md-8">
        <a-card title="Ganti Kata Sandi">
          <a-form
            :rules="rules"
            :model="changePassword"
            layout="vertical"
            :wrapper-col="{
              span: 10,
            }"
            ref="formRef"
          >
            <p>Mengganti kata sandi baru</p>

            <a-form-item has-feedback label="Kata Sandi Lama" name="old_password">
              <a-input-password v-model:value="old_password" placeholder="input password" />
            </a-form-item>

            <a-form-item has-feedback label="Kata Sandi Baru" name="new_password">
              <a-input-password
                v-model:value="new_password"
                placeholder="input password"
                autocomplete="new-password"
              />
            </a-form-item>

            <a-form-item has-feedback label="Ulangi Kata Sandi Baru" name="retype_password">
              <a-input-password
                v-model:value="retype_password"
                placeholder="input password"
                autocomplete="new-password"
              />
            </a-form-item>
            <a-form-item>
              <a-button
                type="danger"
                style="text-align:middle;"
                html-type="submit"
                @click="submit"
                :loading="loading"
                >Simpan</a-button
              >
            </a-form-item>
            <!-- </div> -->
          </a-form>
          <a-alert v-if="messageError" :message="messageError" type="error" show-icon />
        </a-card>
      </div>
    </div>
</template>

<script>
import { ref, watch, onMounted, reactive, provide, toRefs } from 'vue'
import apiClient from '@/services/axios'
import { message } from 'ant-design-vue'
import useUserInfo from '@/composables/useUserInfo'
import { Base64Encode } from '@/helpers/utils'

export default {
  name: 'VbAkun',
  components: {},
  setup(props, { emit }) {
    const { isAdminBk } = useUserInfo()
    const loading = ref(false)
    const changePassword = reactive({
      old_password: null,
      new_password: null,
      retype_password: null,
    })

    const formRef = ref()
    const messageError = ref(null)
    const submit = () => {
      formRef.value
        .validate()
        .then(() => {
          loading.value = true
          apiClient
            .post('/api/auth/change-password', {
              old_password: Base64Encode(changePassword.old_password),
              new_password: Base64Encode(changePassword.new_password),
              retype_password: Base64Encode(changePassword.retype_password),
            })
            .then(() => {
              loading.value = false
              message.success('Password berhasil diubah')
              messageError.value = null
              formRef.value.resetFields()
            })
            .catch(error => {
              message.error('Gagal mengganti password')
              console.log({ error })
              const { response } = error
              if (response && response.data && response.data.length > 0) {
                messageError.value = response.data[0].message
              }
            })
            .finally(() => (loading.value = false))
        })
        .catch(error => {
          console.log('error', error)
        })
    }
    const validatePass2 = async (rule, value) => {
      if (value === '' || value === null) {
        return Promise.reject('Kata sandi belum diinputkan!')
      } else if (value !== changePassword.new_password) {
        return Promise.reject('Ulangi kata sandi tidak sama dengan yang kata sandi baru!')
      } else {
        return Promise.resolve()
      }
    }
    const rules = {
      old_password: [
        {
          required: true,
          message: 'Kata Sandi Lama belum diisi!',
        },
      ],
      new_password: [
        {
          required: true,
          message: 'Kata Sandi Baru belum diisi!',
        },
        { min: 6, message: 'Minimal 6 karakter', trigger: 'blur' },
      ],
      retype_password: [
        { required: true, validator: validatePass2 },
        { min: 6, message: 'Minimal 6 karakter', trigger: 'blur' },
      ],
    }

    return {
      ...toRefs(changePassword),
      changePassword,
      submit,
      rules,
      loading,
      formRef,
      messageError,
      isAdminBk,
    }
  },
}
</script>
